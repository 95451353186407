import axios from '../axios'

export default {
  // Создание проекта
  async createProject ({ commit, state }, projectData) {
    try {
      commit('setProjectCreate', true)

      const params = {
        name: projectData.name,
        description: projectData.description || undefined,
        plan_date: projectData.start_date,
        deadline: projectData.end_date,
        teams: projectData.teams?.map(team => team.id),
        is_active: 0
      }

      const response = await axios.post('projects', params)

      commit('setNewProject', response)

      commit('Notify/setSuccess', `Проект ${response.name} успешно создан`, { root: true })
    } catch (error) {
      if (!error.logout) {
        commit('Notify/setError', 'При создании проекта произошла ошибка. Попробуйте позже.', { root: true })
      }
    } finally {
      commit('setProjectCreate', false)
    }
  },
  async editProject ({ state, commit }, projectData) {
    // Редактирование проекта
    try {
      commit('setProjectCreate', true)

      const params = {
        name: projectData?.name,
        description: projectData?.description,
        plan_date: projectData?.start_date,
        deadline: projectData?.end_date,
        teams: projectData?.teams?.map(team => team.id)
      }

      const response = await axios.put(`projects/${projectData.id}`, params)

      commit('changeProject', response)
      commit('Notify/setSuccess', `Проект ${response.name} успешно изменён`, { root: true })
    } catch (error) {
      if (!error.logout) {
        commit('Notify/setError', 'При редактировании проекта произошла ошибка. Попробуйте позже.', { root: true })
      }
    } finally {
      commit('setProjectCreate', false)
    }
  },
  // Получение списка проектов
  async downloadProjectsList ({ rootState, commit, state }, projectParams) {
    try {
      commit('setProjectLoading', true)

      const teams = rootState.Auth.user.teams
      const teamsParam = {}
      teams.forEach((team, index) => {
        teamsParam[`filter[teams][${index}]`] = team.id
      })

      const response = await axios.get('/get_list_project', {
        params: {
          ...teamsParam,
          per_page: 1000,
          search: projectParams?.searchProject?.length ? projectParams.searchProject : undefined,
          'filter[is_plan_repair]': projectParams.isPlanRepair
        }
      })

      const projects = response.data
      commit('setProjectList', projects)
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке списка произошла ошибка. Попробуйте позже.', { root: true })
      }
    } finally {
      commit('setProjectLoading', false)
    }
  },
  // Удаление проекта
  async deleteProject ({ commit }, id) {
    try {
      commit('setDeleteLoading', true)

      await axios.delete(`projects/${id}`)

      commit('setDeleteProject', id)
    } catch (e) {
      commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
    } finally {
      commit('setDeleteLoading', false)
    }
  },
  // Клонировать проект
  async cloneProject ({ state, commit }, project) {
    try {
      commit('setCloneLoading', true)

      const response = await axios.post(`projects/${project.id}/clone`, project)
      commit('setNewProject', response)

      commit('Notify/setSuccess', 'Проект успешно склонирован', { root: true })
    } catch (e) {
      if (e.handled || e.logout) return
      commit('Notify/setError', 'При клонировании произошла ошибка. Попробуйте позже.', { root: true })
    } finally {
      commit('setCloneLoading', false)
    }
  },
  // Архивировать проект
  async archiveProject ({ commit }, id) {
    const data = new FormData()
    data.set('is_active', 1)

    try {
      commit('setArchiveLoading', true)

      const response = await axios.put(`projects/${id}`, data)

      commit('updateProject', response)
    } catch (e) {
      commit('Notify/setError', 'При архивации произошла ошибка. Попробуйте позже.', { root: true })
    } finally {
      commit('setArchiveLoading', false)
    }
  },
  // Разархивировать проект
  async unArchiveProject ({ commit }, id) {
    const data = new FormData()
    data.set('is_active', 0)

    try {
      commit('setUnArchiveLoading', true)

      const response = await axios.put(`projects/${id}`, data)

      commit('updateProject', response)
    } catch (e) {
      commit('Notify/setError', 'При разархивации произошла ошибка. Попробуйте позже.', { root: true })
    } finally {
      commit('setUnArchiveLoading', false)
    }
  },
  // Переместить проект из АПР в стандартные проекты
  async moveAprProject ({ commit }, id) {
    try {
      commit('setAprProjectMoveLoading', true)

      await axios.post('repairs/move_plan_to_real', {
        repairs: [ id ]
      })

      // Нужно удалить из списка проектов все проекты, у которых есть такой repair id
      commit('setDeleteAprProjectAfterMove', id)
    } catch (e) {
      commit('Notify/setError', 'При переносе проекта из АПР произошла ошибка. Попробуйте позже.', { root: true })
    } finally {
      commit('setAprProjectMoveLoading', false)
    }
  }
}
