import axios from '../axios'

export default {
  // Получение списка задач
  async downloadTaskList ({ rootState, commit }, params) {
    try {
      commit('setTaskLoading', true)
      const filterResponsibleIsChecked = rootState.Task.filterResponsibleIsChecked ? 1 : 0
      const teams = rootState.Auth.user.teams
      const teamsParam = {}
      teams.forEach((team, index) => {
        teamsParam[`filter[teams][${index}]`] = team.id
      })

      const projectType = rootState.Project.projectType === 'Проекты АПР' ? 1 : 0
      const filters = {
        ...teamsParam,
        'filter[state]': 1,
        'sort[column]': params.sortValue ?? 'updated_at',
        'sort[type]': params.sortType ?? 'desc',
        'filter[statuses][0]': 'open',
        'filter[statuses][1]': 'progress',
        'filter[statuses][2]': 'done',
        'filter[statuses][3]': 'verify',
        'filter[projects][0]': params.projectId,
        'filter[my_tasks_today]': filterResponsibleIsChecked || undefined,
        page: params.currentPage
      }

      if (projectType) {
        filters['filter[is_plan_repair]'] = projectType
      }

      const response = await axios.get('/tasks', {
        params: filters
      })
      commit('setTaskList', response.data)
      commit('setTaskPageData', response)
    } catch (error) {
      console.error('error', error)
      if (!error.logout) {
        commit('Notify/setError', 'При загрузке списка рабочих заданий произошла ошибка. Попробуйте позже.', { root: true })
      }
    } finally {
      commit('setTaskLoading', false)
    }
  },
  async downloadSubtaskList ({ rootState, commit }, id) {
    try {
      const response = await axios.get(`/task/${id}/subtasks/list`)
      const setSubTaskNodes = (nodes, id) => {
        for (let i = 0; i < nodes.length; i++) {
          if (nodes[i].id === id) return (nodes[i].subtasks = response)
          if (nodes[i]?.subtasks?.length) setSubTaskNodes(nodes[i].subtasks, id)
        }
      }
      setSubTaskNodes(rootState.Task.taskList, id)
    } catch (error) {
      if (!error.logout) {
        commit('Notify/setError', 'При загрузке списка подзадачь произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async deletedTask ({ commit, state }, task) {
    // удаление задач из списка
    try {
      commit('setTaskDeleteLoading', true)
      await axios.delete(`tasks/${ task.id }`)
      commit('deleteTask', task)
    } catch (e) {
      commit('Notify/setError', 'При удалении рабочего задания произошла ошибка. Попробуйте позже.', { root: true })
    } finally {
      commit('setTaskDeleteLoading', false)
    }
  },
  async getCriticalPathTasks ({ commit }, projectId) {
    // Получения списка задач, которые находятся на критическом пути
    try {
      return await axios.get('gantt/get_list_task_on_critical_path', {
        params: {
          'projects[0]': projectId
        }
      })
    } catch (error) {
      console.error(error)
    }
  },
  async updateCriticalPathTasks ({ commit }, tasks) {
    // Отправка списка id задача, которые находятся на критическом пути
    try {
      await axios.post('tasks/critical_path', {
        tasks_id: tasks
      })
    } catch (error) {
      console.error(error)
    }
  }
}
