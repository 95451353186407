export default {
  setCurrentPage: (state, pageNumber) => {
    state.currentPage = pageNumber
  },
  setTaskPageData: (state, data) => {
    state.currentPage = data.current_page
    state.count = data.count
    state.totalPages = data.total_pages
    state.total = data.total
    state.perPage = data.per_page
  },
  setTaskLoading: (state, data) => {
    state.isTasksLoading = data
  },
  setTaskList: (state, data) => {
    if (data) {
      // TODO Миша удалит сабтаски удалить отсюда
      state.taskList = data.map(items => {
        const { subtasks, ...rest } = items
        if (!subtasks) return items
        return ({ ...rest, subtasks_count: subtasks?.length || 0 })
      })
    }
  },
  setTaskDeleteLoading: (state, data) => {
    state.isTaskDeleteLoading = data
  },
  deleteTask: (state, task) => {
    state.taskList.forEach(item => {
      // Если не главная задача
      if (task.state_id) {
        deleteTaskInSubtasks(state.taskList)
      } else {
        const taskIndex = state.taskList.findIndex(t => t.id === task.id)

        if (taskIndex !== -1) {
          state.taskList.splice(taskIndex, 1)
        }
      }
    })

    function deleteTaskInSubtasks (tasks) {
      for (let i = 0; i < tasks.length; i++) {
        const subtask = tasks[i]

        if (subtask.id === task.id) {
          tasks.splice(i, 1)
          return
        }

        if (Array.isArray(subtask.subtasks)) {
          deleteTaskInSubtasks(subtask.subtasks)
          if (subtask.subtasks.length === 0) {
            subtask.subtasks = []
          }
        }
      }
    }
  },
  setColorStatuses: (state, statuses) => {
    state.tasksColorStatuses = statuses
  },
  setShowGantt: (state, value) => state.isShowGantt = value,
  setFilterResponsibleIsChecked: (state, value) => state.filterResponsibleIsChecked = value
}
