export default {
  projectList: state => state.projectList,
  projectListLength: state => state.projectList.length,
  projectType: state => state.projectType,
  isProjectLoading: state => state.isProjectLoading,
  selectedProjectId: state => state.selectedProjectId,
  isProjectCreate: state => state.isProjectCreate,
  isProjectDelete: state => state.isProjectDelete,
  isProjectClone: state => state.isProjectClone,
  isProjectArchive: state => state.isProjectArchive,
  isProjectUnArchive: state => state.isProjectUnArchive,
  isAprProjectMove: state => state.isAprProjectMove
}
